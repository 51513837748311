@import "./bower/bootstrap/less/bootstrap";
@import "./bower/font-awesome/less/font-awesome";
@import "fonts";
@import "variables";
/*
* Vendor specific
*/
@import "vendors/parsley";
@import "vendors/slick";

html,
body {
  height: 100%;
}

// Override - live edit did not test generation of this in style.css - made edit directly in style.css - RF

.nav>li>a {
  padding: 10px 15px 10px 0;
}

#main {
  overflow: hidden;
}

#container {
  min-height: 100%;
  margin-bottom: -44px;
  padding-bottom: 44px;
}

#sidebar {
  padding-top: 1 * @font-size-h1;
}

.mt-1 {
  margin-top: @line-height-computed * 1px;
}

.mt-2 {
  margin-top: @line-height-computed * 2px;
}

.mt-3 {
  margin-top: @line-height-computed * 3px;
}

.mb-1 {
  margin-bottom: @line-height-computed * 1;
}

.mb-2 {
  margin-bottom: @line-height-computed * 2;
}

.mb-3 {
  margin-bottom: @line-height-computed * 3;
}

.align-right {
  float: right;
}

.align-left {
  float: left;
}

.align-right,
.align-left {
  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
    float: none;
  }
}

.clear-both {
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.text-align-center {
  text-align: center;
}

.vertical-align-middle {
  position: relative;
  top: 50%;
  .translate(0%, -50%);
}

.horizontal-align-center {
  .translate(-50%, -50%);
  left: 50%;
  position: absolute;
  z-index: 99;
}

.panel-relatedpanel.slideshow {
  overflow: hidden;
  // margin-top: -20px;
  // margin-top: 120px;

  @media (max-width: @screen-xs) {
    margin-top: 77px;

    .caption h2 {
      font-size: 14px;
    }

    .caption {
      font-size: 12px;
    }
  }

  .slick-prev {
    left: 15px;
    z-index: 3;
    top: 55%;

    @media (max-width: @screen-xs) {
      top: 50%;
    }
  }

  .slick-next {
    right: 15px;
    z-index: 3;
    top: 55%;

    @media (max-width: @screen-xs) {
      top: 50%;
    }
  }
}

.slick-slide {
  overflow: hidden;
  outline: 0;

  a {
    outline: 0;
  }

  .slick-dots li button:before,
  .slick-dots li.slick-active button:before {
    color: @white;
    opacity: 0.9;
  }
}

.carousel-inner,
[data-slick-carousel-default] {
  margin-bottom: 0;
}

.slide-btns {
  margin: 20px 0;
}

.caption {
  font-size: @font-size-base;
  min-height: @font-size-base;
  box-sizing: content-box;
  display: none;
  background-color: @brand-red;
  color: @white;
  position: absolute;
  top: 45%;
  left: 0;
  padding: 10px 20px 20px 40px;
  width: 40%;
  height: auto;
  margin-left: 0;
  opacity: 0.8;
  background-image: url("/img/caption-bg.png");
  background-position: bottom right;
  background-repeat: no-repeat;

  h2 {
    color: @white;
    font: normal bold 24px Georgia, Serif;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
    font-size: 21px;
    margin-top: 20px;
  }

  p {
    margin-bottom: 0;
  }

  .article-image-attribution {
    float: right;
  }
}

.jumbotron {
  *:last-child {
    margin-bottom: 0;
  }
}

.page-title {
  text-align: center;
  margin-top: @font-size-h1;
  margin-bottom: @font-size-h1;

  &.page-title-left {
    text-align: left;
  }
}

.pages {
  .page-header {
    h1 {
      background-color: @brand-red;
      display: inline-block;
      padding: 10px 10px 10px 20px;
      color: @white;
      border-radius: 0 10px 10px 0;
      font-size: 21px;
    }
  }

  .jumbotron {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    color: inherit;
    // background-color: @brand-red;
    color: @gray-dark;

    @media (max-width: @screen-xs) {
      padding-left: 30px;
      padding-right: 30px;

      p {
        font-size: 14px;
      }
    }
  }
}

.page-header {
  margin-top: @font-size-h1;
  padding-bottom: 11px;
  margin: 48px 0 24px;
  border-bottom: 1px solid transparent;

  h1,
  h2,
  h3 {
    margin-top: 0;
  }
}

.breadcrumb {
  background-color: transparent;
  font-size: 12px;
  margin-top: 10px;

  a {
    color: @gray-dark;
  }

  color: red;
  margin-bottom: 5px;
}

.banner {
  border-top: 1px solid @gray-lighter;
  height: 175px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 30px;
  padding-bottom: 20px;

  .page-header {
    font-size: 21px;
  }
}

.featureArea {
  overflow: hidden;
  padding: 20px;
  margin-top: 10px;
  background-color: #41525a;
  color: @white;
  margin-bottom: 20px;
  background-image: url(/img/header-bg.png);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: @screen-xs) {
    background-size: cover;
    background-position: center;
  }

  .welcome-msg {
    margin-top: -50px;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 1);

    a {
      color: #c0e2ff;
      text-decoration: none;
    }

    a:hover {
      color: #f7ffc0;
    }
  }
}

.singleEntry {
  .page-header {
    text-transform: none;
  }
}

body.pages {
  .page-header {
    text-transform: uppercase;
  }
}

body.sponsorspage {
  .media {
    float: left;
    margin: 0 20px 0 0;
    width: 200px;
  }
}

body.eventspage,
body.newspage {
  .media-image {
    float: left;
    margin: 0 20px 0 0;
  }
}

.news-block,
.events-block {
  border: 1px solid @gray;
  margin-bottom: 20px;
  -moz-box-shadow: 2px 5px 6px 2px #ececec;
  -webkit-box-shadow: 2px 5px 6px 2px #ececec;
  box-shadow: 2px 5px 6px 2px #ececec;
}

.events-block {
  .event_title {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px dotted #ccc;
    display: block;
    overflow: hidden;
    font-size: 21px;

    a {
      color: #5f7c8a;
      text-decoration: none;
    }
  }

  .dates {
    color: @brand-red;
  }

  overflow: hidden;
  padding: 20px;
}

.news-block {
  .media {
    header {
      border-bottom: 1px dotted #ccc;
      margin-bottom: 20px;
    }

    .media-heading {
      a {
        color: #5f7c8a;
      }
    }
  }
}

.events_tabs,
.guidelines_wrap,
.faq_wrap,
.news_wrap,
.members_wrap {
  border: 1px solid @gray-lighter;
  padding: 20px 0 40px 0;
  overflow: hidden;
  background-color: @white;
  margin: 0px 0 40px 0;
}

.news_wrap {
  border: none;
}

.member-heading {
  small {
    color: #db261c;
  }
}

.faq_wrap {
  padding: 20px 20px 40px 20px;
  border-top-color: transparent;
}

.events_tabs {
  background-color: transparent;
  border-color: transparent;
  padding: 0;

  .nav-tabs>li {
    width: 100%;
  }

  .tab-content {
    background-color: @white;
    padding: 1px 20px 20px 20px;
  }

  .nav-tabs>li>a {
    border-bottom-color: @gray-light;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom: 1px dotted @gray;
    background-color: @white;
    border-radius: 0;

    .event_title {
      color: @brand-red;
    }

    .dates {
      color: @gray-dark;
    }
  }
}

.alt-nav {
  ul {
    margin: 0;
    padding: 0;
    border-bottom: 2px solid #ececec;
    border-left: 1px solid #ececec;

    li {
      a {
        display: block;
        padding: 10px 20px;
        border-right: 1px solid #ececec;
        color: #555;
        background-color: #f9f9f9;
      }
    }

    .active>a {
      background-color: #db261c;
      color: #fff;
    }
  }
}

.events_tabs {
  .nav-tabs>li {
    margin-bottom: 0px;
    border-right-color: transparent;
  }

  .nav-tabs>li.active>a,
  .nav-tabs>li.active>a:focus,
  .nav-tabs>li.active>a:hover {
    color: @gray-5;
    background-color: #efefef;
    border: 1px solid transparent;

    cursor: default;
  }

  .date-label {
    color: @gray-dark;
  }

  .event_date {
    color: @brand-red;
    margin-right: 20px;
  }
}

.calendar-icon {
  position: absolute;
}

.past_event {
  background: @light;
  border: 1px solid @light-border;
  overflow: hidden;
}

body.faqspage {
  .panel.panel-default {
    margin-bottom: 2px;
  }

  .item-block-1 {
    margin-top: 20px;
  }
}

body.memberspage {
  .media-object {
    float: left;
    margin: 0 20px 0 0;
  }

  h4 small {
    color: @brand-red;
  }
}

.faq-panel {
  margin-bottom: 2px;
}

.sidebarBlock {
  margin-top: 18px;
  margin-top: 20px;

  .btn-default {
    color: @gray-dark;
    background-color: @white;
    border-color: @gray;
    border-bottom: 3px solid @brand-red;
    margin-bottom: 20px;
  }
}

.cta-buttons {
  margin-bottom: 40px;
}

.article {
  padding-bottom: @grid-gutter-width;

  .article-title {
    &:extend(.page-title);
  }

  .article-byline {
    position: relative;
    text-align: center;
    margin-bottom: @font-size-h1;
  }

  h2 {
    margin-bottom: 0.5em;
  }
}

.article-pages {
  // padding: 20px;
  background-color: @white;
  margin-top: 0px;
  margin-bottom: 40px;
}

@media (min-width: @screen-sm) {
  .meta-byline {
    width: 70%;
  }
}

.meta-byline {
  margin-bottom: @padding-base-vertical;
}

.article-image {
  margin-bottom: @line-height-computed;

  .caption {
    .clearfix();
    padding: 9px;
  }
}

[class*="col-"].align-left {
  padding-left: 0;
  padding-right: 15px;

  @media (max-width: @screen-md) {
    padding-right: 0;
  }
}

[class*="col-"].align-right {
  padding-right: 0;
  padding-left: 15px;

  @media (max-width: @screen-md) {
    padding-left: 0;
  }
}

[class*="col-"].align-center {
  text-align: center;

  .caption {
    text-align: left;
  }

  .thumbnail {
    display: inline-block;
  }
}

.article-image.align-left,
.article-image.align-right {
  @media (min-width: @screen-xs) {
    max-width: 50%;
  }

  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
  }
}

.article-body {
  .clearfix();
}

// Media extends
.media {
  border: 1px solid transparent;
  padding: @panel-body-padding;
  margin-bottom: @font-size-base;
  border-radius: @border-radius-small;

  .media-right,
  .media>.pull-right {
    padding-left: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-left,
  .media>.pull-left {
    padding-right: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-date-byline {
    border-top: 1px solid @gray-light;
    border-bottom: 1px solid @gray-light;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: @padding-base-vertical;
  }

  .media-summary {
    p:last-child {
      margin-bottom: @padding-base-vertical;
    }
  }

  .media-heading {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 21px;

    a {
      color: @gray-dark;
    }
  }

  .media-footer {
    text-align: right;
    border-top: 1px dotted @gray-lighter;
    border-bottom: 1px dotted @gray-lighter;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
    margin-top: 20px;

    a {
      color: #333;
    }
  }

  .post-date {
    color: #8c8c8c;
    font-size: 12px;
    margin: 20px 0;
  }

  &.media-responsive {

    .media-right,
    .media-left {
      @media (max-width: @screen-xs) {
        display: block;
        float: none !important;
        padding-right: 0;
        padding-left: 0;

        .media-object {
          width: 100%;
        }
      }

      @media (min-width: @screen-xs + 1) {
        .media-object {
          width: 200px;
        }
      }

      @media (min-width: @screen-md) {
        .media-object {
          width: 160px;
        }
      }
    }
  }

  &-galleries {
    padding: 4px;
    background-color: @white;

    .media-summary {
      padding: @padding-small-vertical;
    }
  }
}

.media-events.media {
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 35px;
}

.section_title {
  text-transform: uppercase;
  font-size: 24px;
  font-family: "Roboto Slab", serif;
  color: #c01a37;
  padding: 10px 20px;
  display: inline-block;
}

.about_section {
  // margin-bottom: 100px;
  background-color: #fbf8f4;

  .section_title {
    text-transform: uppercase;
    font-family: "Roboto Slab", serif;
    font-size: 28px;
    color: #c01a37;
    padding: 10px 20px;
    display: inline-block;
  }

  .lead {
    font-size: 18px;
    line-height: 28px;
  }

  a.btn.btn-default.btn-red {
    color: #fff;
    background-color: #c01a37;
    border-color: transparent;
    border-radius: 20px;
    padding: 10px 40px;
    font-size: 14px;

    @media (max-width: @screen-xs) {
      float: none !important;
    }
  }

  a.btn.btn-default.btn-red:hover {
    background-color: #8badbd;
  }
}

.congress_panel {
  background-color: #0b384e;
  overflow: hidden;
  padding: 80px 0 100px 0;
  color: #fff;
  background-image: url("/img/event_image.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .section_title {
    text-transform: uppercase;
    font-family: "Roboto Slab", serif;
    font-size: 28px;
    color: #bf203a;
    padding: 10px 20px;
    display: inline-block;

    small {
      font-size: 46%;
      color: #ccc;
      font-family: "Ubuntu", sans-serif;
    }
  }

  a.btn.btn-default.btn-red {
    color: #fff;
    background-color: #c01a37;
    border-color: transparent;
    border-radius: 20px;
    padding: 10px 40px;
    font-size: 14px;
  }

  a.btn.btn-default.btn-red:hover {
    background-color: #8badbd;
  }
}

.btn.btn-default.btn-red {
  color: #fff;
  background-color: #c01a37;
  border-color: transparent;
  border-radius: 20px;
  padding: 10px 40px;
  font-size: 14px;
  margin-top: 0px;

  @media (max-width: @screen-xs) {
    margin-top: 0;
  }
}


.events_wrap {
  a.btn.btn-default.btn-red {
    color: #fff;
    background-color: #c01a37;
    border-color: transparent;
    border-radius: 20px;
    padding: 10px 40px;
    font-size: 14px;
    margin-top: -20px;

    @media (max-width: @screen-xs) {
      margin-top: 0;
    }
  }

  .panel-heading {
    margin-bottom: 5px;
    padding: 10px 15px 0;

    @media (max-width: @screen-xs) {
      text-align: center;
    }
  }

  a.btn.btn-default.btn-red:hover {
    background-color: #8badbd;
  }

  .media-events {
    .event_title {
      color: @brand-red;
    }

    .event_dates {
      font-size: 14px;
      margin-top: 10px;
    }

    @media (max-width: @screen-xs) {
      text-align: center;

      .btn.pull-left {
        float: none !important;
      }
    }
  }

  .event-slide {

    .slick-next:before,
    .slick-prev:before {
      color: #8cadbd;
      font-size: 21px;
    }

    .slick-slide {
      height: 245px;
    }

    .media-body {
      padding-left: 20px;

      .btn-default {
        background-color: #8badbd;
        border: none;
        border-radius: 20px;
        color: #fff;
      }

      .btn-default:hover {
        background-color: #c01a37;
      }
    }

    .media-events.media {
      max-height: 205px;
      overflow: hidden;
    }

    .slick-next,
    .slick-prev {
      top: 44%;
    }

    .slick-next:before,
    .slick-prev:before {
      display: none;
    }

    .slick-prev {
      left: 0;
      z-index: 1;
      font-size: 21px;
      color: #28b2ff;
    }

    .slick-next {
      right: 0;
      z-index: 1;
      font-size: 21px;
      color: #28b2ff;
    }
  }

  .media .media-footer {
    border-color: transparent;
  }
}

.video_panel {
  padding: 80px 0;
  border-bottom: 1px solid #ececec;
  overflow: hidden;
  margin-bottom: 20px;
}

.panel-heading {
  font-size: 21px;
  color: #7d7d7d;
  text-transform: uppercase;
  font-family: "Roboto Slab", serif;
  margin-bottom: 5px;
  padding: 10px 15px 0;
}

.sponsors-wrap {
  border-left: 2px solid #e6e6e6;
  overflow: hidden;
  padding-left: 10px;

  @media (max-width: @screen-xs) {
    border: none;

    .panel-heading {
      text-align: center;
      margin-top: 20px;
    }
  }

  .sponsors-panel {
    margin-bottom: 5px;
  }

  .slick-next,
  .slick-prev {
    z-index: 1001;
    color: #8cadbd;
  }

  .sponsors-slide {
    margin-top: 15px;

    .slick-next,
    .slick-prev {
      top: 45%;
    }

    .slick-next:before,
    .slick-prev:before {
      display: none;
    }

    .slick-prev {
      left: 0;
      z-index: 1;
      font-size: 21px;
      color: #28b2ff;
    }

    .slick-next {
      right: 0;
      z-index: 1;
      font-size: 21px;
      color: #28b2ff;
    }
  }
}

.tabbed_section {
  // min-height:500px;
  margin-bottom: 30px;
  background-color: @light;
  // background-image: url('/img/tabbed-section-bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  border: 1px solid @light-border;
  padding-bottom: 30px;
  margin-top: 100px;

  .section_title {
    text-transform: uppercase;
    font-family: "Roboto Slab", serif;
    font-size: 18px;
    border-bottom: 2px solid @brand-red;
    border-top: 2px solid transparent;
    background-color: @white;
    padding: 10px 20px;
    display: inline-block;
  }

  .media {
    background-color: @white;
  }

  .media_list .thumbnail {
    float: left;
    margin: 0 20px 0 0;
  }

  .panel-default>.panel-heading {
    color: @white;
    background-color: @brand-red;
    /* border-color: @whitefff; */
  }

  .btn-circle {
    width: 100px;
    text-align: center;
    padding: 30px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 100%;
    margin-bottom: 0;
    margin-top: 0 !important;
  }
}

.tabbed_section {
  .nav-tabs>li>a:hover {
    color: @gray-dark;
  }

  .nav-tabs>li.active>a,
  .nav-tabs>li.active>a:focus,
  .nav-tabs>li.active>a:hover {
    background-color: @light;
  }
}

.tabbed_section .nav-tabs>li {
  width: 16%;
}

.tabbed_section .nav-tabs>li>a {
  color: @brand-red;
  padding: 10px;
}

.tabbed_section .nav-tabs {
  width: 100%;
  border-bottom-color: transparent;
  margin: -94px auto 20px auto;
}

.sponsors-logos {
  min-height: 200px;
  margin-bottom: 30px;
  text-align: center;

  .section_title {
    text-transform: uppercase;
    font-family: "Roboto Slab", serif;
    font-size: 24px;
    color: #626262;
    padding: 20px 20px 35px 20px;
    display: inline-block;
  }

  img {
    @media (max-width: @screen-xs) {
      display: initial;
    }
  }
}

.wizard {
  margin: 0 auto 20px auto;
}

.wizard .nav-tabs {
  position: relative;
  margin: 0 auto 40px auto;
  margin-bottom: 0;
  border-bottom-color: @light-border;
}

.wizard>div.wizard-inner {
  position: relative;
}

.connecting-line {
  height: 2px;
  background: @light-border;
  position: absolute;
  width: 80%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  z-index: 1;
}

.wizard .nav-tabs>li.active>a,
.wizard .nav-tabs>li.active>a:hover,
.wizard .nav-tabs>li.active>a:focus {
  color: @gray-5;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}

span.round-tab {
  width: 70px;
  height: 70px;
  line-height: 70px;
  display: inline-block;
  border-radius: 100px;
  background: @white;
  border: 2px solid @light-border;
  z-index: 2;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 25px;
}

span.round-tab i {
  color: @gray-5;
}

.wizard li.active span.round-tab {
  background: @white;
  border: 2px solid @wizard;
}

.wizard li.active span.round-tab i {
  color: @wizard;
}

span.round-tab:hover {
  color: @gray-dark;
  border: 2px solid @gray-dark;
}

.wizard .nav-tabs>li {
  width: 25%;
}

.wizard li:after {
  content: " ";
  position: absolute;
  left: 46%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: @wizard;
  transition: 0.1s ease-in-out;
}

.wizard li.active:after {
  content: " ";
  position: absolute;
  left: 46%;
  opacity: 1;
  margin: 0 auto;
  bottom: 0px;
  border: 10px solid transparent;
  border-bottom-color: @wizard;
}

.wizard .nav-tabs>li a {
  width: 70px;
  height: 70px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
}

.wizard .nav-tabs>li a:hover {
  background: transparent;
}

.wizard .tab-pane {
  position: relative;
  padding-top: 10px;
}

.wizard h3 {
  margin-top: 0;
}

.step_content {
  margin-bottom: 20px;
  overflow: hidden;
  text-align: left;
}

.featured-team-members {
  background-color: @light;
  background-image: url(/img/tabbed-section-bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  border: 1px solid @light-border;
  text-align: center;

  .team-members {
    min-height: 440px;
    text-align: center;
    // background: @gray-lighter;
    padding: 20px 0;

    h4 {
      small {
        color: @brand-red;
      }
    }
  }

  .section_title {
    text-transform: uppercase;
    font-family: "Roboto Slab", serif;
    font-size: 18px;
    border-top: 2px solid @gray-lighter;
    border-bottom: 2px solid @gray-lighter;
    padding: 10px 20px;
    display: inline-block;
    margin-bottom: 30px;
    background-color: #fff;
  }
}

.team-members-slide {
  .member-block {
    background-color: @white;
  }
}

.team-members {
  .slick-dots {
    margin-bottom: 40px;
  }

  .member-block {
    background-color: @white;
    text-align: center;
    border: 1px solid @gray;
    margin-bottom: 20px;
    -moz-box-shadow: 2px 5px 6px 2px #ececec;
    -webkit-box-shadow: 2px 5px 6px 2px #ececec;
    box-shadow: 2px 5px 6px 2px #ececec;

    small {
      color: #afacac;
    }

    .media-heading {
      margin-bottom: 20px;
    }

    .media-summary {
      font-size: 14px;
    }
  }
}

.member-block {
  .thumbnail {
    border-radius: 0;
    overflow: hidden;
    // width: 60%;
    height: 135px;
    margin: 0 auto 20px;
  }

  .media-summary {
    font-size: 13px;
    margin-bottom: 20px;
  }

  .media .media-heading {
    font-size: 18px;
  }

  .section_title {
    text-transform: uppercase;
    font-family: "Roboto Slab", serif;
    font-size: 18px;
    border-bottom: 2px solid @brand-red;
    border-top: 2px solid transparent;
    background-color: @white;
    padding: 10px 20px;
    display: inline-block;
    margin-bottom: 40px;
  }
}

.social_icons {
  margin: -20px 0 20px 0;

  .btn-default {
    border-color: transparent;
    border-radius: 0;
    padding: 0;
  }

  .btn-default.external-link {
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 100% !important;
    padding: 0px 2px;
    margin: 5px 2px 2px 2px;
  }

  .login-btn {
    margin-left: 5px;
    font-size: 13px;
    text-transform: uppercase;
    color: #555;
    // border-left:2px solid #333;
    padding: 5px 13px 10px 10px;

    .fa {
      color: #db261c;
    }
  }

  .login-btn:hover {
    background-color: transparent;

    .fa {
      color: #333;
    }
  }

  .login-nav-divider {
    font-size: 21px;
    color: #333;
  }

  .twitter-btn {
    color: #44a2f0;
  }

  .facebook-btn {
    color: #3d549c;
  }

  .login-links {
    color: #c7c7c7;
    font-size: 14px;

    a {
      color: #656363;
      padding: 0 13px 0 13px;
    }

    a:hover {
      color: #db261c;
    }
  }

  @media (max-width: @screen-xs) {
    margin-top: 5px;
    float: none;
    border-color: transparent;
  }
}

.nav-stacked {
  .nav-stacked {
    >li>a {
      padding-left: 40px;
    }
  }
}

.pagination {
  text-align: center;
  width: 100%;

  a {
    padding: 10px;
    color: @gray-dark;
    border: 1px solid @gray;
    border-radius: 5px;
  }
}

.edit-this-page {
  font-size: 14px !important;
  background: transparent;
  display: inline-block;
  padding: 4px;
  height: 22px;
  width: 22px;
  line-height: 14px;
  color: @gray;

  &:hover {
    background: @gray;
    color: @white;
  }
}

#footer {
  background: #041928;
  padding: 20px 0;
  margin-top: 20px;

  .disclaimer {
    margin: 10px 0 0px 0;
    color: #8c8d8e;
    font-size: 12px;
    overflow: hidden;
  }

  .footer_nav {
    .navbar-nav {
      width: 100%;

      li {
        display: inline-block;
        float: none;

        @media (max-width: @screen-xs) {
          display: block;
        }

        a {
          color: #d7d7d7;
          text-transform: uppercase;
        }

        a:hover {
          background-color: transparent;
          color: #db261c;
        }
      }

      li:first-child {
        a {
          padding-left: 0;

          @media (max-width: @screen-xs) {
            padding-left: 15px;
          }
        }
      }

      li.item-level-2 {
        display: block;
      }

      li.item-level-2:first-child a {
        padding-left: 20px !important;
      }

      .item-level-3 {
        display: block;
      }

      .item-level-3:first-child a {
        padding-left: 20px !important;
      }

      .social_icons {
        margin: 0 0;
        display: inline-block;

        a.login-btn {
          border-left: 1px solid #ccc;
          background-color: transparent;
          color: #d7d7d7;
          text-transform: uppercase;

          @media (max-width: @screen-xs) {
            border-left: 1px solid transparent;
            padding-left: 10px;
          }

          .fa {
            color: #d7d7d7;
          }
        }

        a.login-btn:hover {
          color: #db261c;
        }
      }
    }

    .dropdown-menu {
      top: auto !important;
      bottom: 100% !important;
    }

    a.footer_logo {
      img {
        @media (max-width: @screen-xs) {
          width: 35% !important;
        }
      }
    }
  }
}

.site-footer {
  color: #b1b3b5;
  font-size: 10px;
}

.site-header {
  // background: @white;
  border-bottom: 3px solid @light-border;
  margin-bottom: 0px;
  position: relative;
  z-index: 2;
}

// Nav bar dropdown

.centered-nav {
  text-align: center;
}

.navbar-brand {
  overflow: hidden;
  padding: 0 15px;
  min-height: 140px;

  @media (max-width: @screen-xs) {
    min-height: 80px;
  }
}

.navbar-brand>img {
  margin-top: -18px;

  @media (max-width: @screen-xs) {
    height: 90px !important;
    margin-top: -6px;
  }
}

.navbar-default {
  background-color: #fff;
}

.navbar-collapse.collapse {
  margin-top: 40px;
}

.navbar .navbar-nav {
  float: none;
}

.navbar-default .navbar-nav>li>a {
  color: @gray-5;
  padding: 10px 6px 10px 6px;
  text-transform: uppercase;
  font-size: 15px;

  &:hover,
  &:focus {
    color: @brand-red;
    text-decoration: none;
  }
}

.navbar-default .navbar-nav>.active>a {
  // background-color: @brand-red;
  text-align: center;
  color: @gray-5;

  @media (max-width: @screen-xs) {
    text-align: left;
  }

  &:hover,
  &:focus {
    color: @white;
    text-decoration: none;
  }
}

.navbar-default .navbar-nav>.active>a:after {
  content: "\2014";
  color: #db261c;
  display: block;
  font-size: 28px;
  line-height: 10px;
}

.navbar-default .navbar-nav>.dropdown.active>a:after {
  content: "\f078";
  color: #333;
  font-size: 0.75em;
}

.navbar-default .navbar-nav>.active>a:hover {
  // background-color: @gray-dark;
  color: #000;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
  color: @brand-red;
  background-color: transparent;
}

.navbar-default .navbar-nav .open .dropdown-menu>.active>a {
  background-color: @brand-red;
}

.navbar-default .navbar-nav .open .dropdown-menu>li>a:focus,
.navbar-default .navbar-nav .open .dropdown-menu>li>a:hover {
  @media (max-width: @screen-xs) {
    color: #8c8c8c;
    background-color: #ececece;
  }
}

.navbar-nav>li.dropdown {
  >a {
    color: @gray-dark;
    padding-right: 30px;
    padding-bottom: 10px;

    &:after {
      .fa-icon();
      content: @fa-var-chevron-down;
      position: absolute;
      top: ~"calc(50% - 6px)";
      right: 9px;
      font-size: 0.75em;
    }

    &:hover,
    &:focus {
      color: @brand-red;
      text-decoration: none;
    }
  }
}

.dropdown-menu {
  background-color: @gray-dark;
  border-radius: 0;
  padding: 0;

  li>a {
    display: block;
    padding: 10px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.5;
    color: @white;
    background-color: #415259;
    white-space: nowrap;
    border-bottom: 1px dotted #6c838c;
  }
}

.dropdown-submenu {
  .dropdown-menu {
    top: 5px;
  }
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
  color: @white;
  text-decoration: none;
  outline: 0;
  background-color: @brand-red;
}



// Nav form

.navbar-form {
  .form-control {
    background: transparent;
    border: 0;
    border-bottom: 1px solid @gray-light;
    border-radius: 0;
    color: @gray-lighter;
    box-shadow: none;
  }
}

.form-control {
  color: #333;
}

// share

.article-sharing {
  min-height: 22px;
  margin-left: -5px;
}

.share-btn {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  color: #bfbfbf;
  font-size: 14px;
}

.share-btn:hover {
  color: #92d6e6;
}

// List group extends
.list-group-item-meta {
  font-size: @font-size-small;
}

/* notifications */
#notifications {
  z-index: 101;
}

body.ltr #notifications {
  left: 0;
}

body.rtl #notifications {
  right: 0;
}

#notifications.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
}

#notifications {
  text-align: center;

  .notification {
    .alert;

    @media (max-width: @screen-xs) {
      margin-top: 15%;
    }
  }

  .notification[class*="notice"] {
    .alert-info;
  }

  .notification[class*="error"] {
    .alert-danger;
  }

  .notification {
    margin-bottom: 0;
    border-radius: 0;

    a {
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .notification+.notification {
    border-top: 0;
  }
}

// Dropdown sub-menu

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropdown-submenu>a:after {
  .fa-icon();
  content: @fa-var-chevron-right;
  position: relative;
  top: ~"calc(50% - 6px)";
  right: -9px;
  font-size: 0.75em;
}

.dropdown-submenu:hover>a:after {
  border-left-color: @white;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

h1.gtmwarning {
  text-align: center;
  background: red;
}

/* Gallery */

.grid-sizer,
.grid-item {
  width: 33%;
  margin-bottom: 5px;
}

.gallery-image {
  padding: 5px;
  width: 33%;
  float: left;
  position: relative;
}

.gallery-image-link {
  display: block;
  position: relative;
  padding-bottom: 75%;
  overflow: hidden;

  .img-responsive {
    position: absolute;
    .square(100%);
  }

  .caption {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    padding: 5px;
    color: @white;
  }
}

.grid-item-width2 {
  width: percentage(8/12);
}

.grid-item-height2 {
  img {
    width: 100%;
    overflow: hidden;
  }

  .lightbox {
    padding-bottom: 100%;
  }
}

.gallery-link {
  position: relative;

  &:hover .caption {
    display: block;
  }

  .caption {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: @white;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }
}

.mfp-description {
  color: #ccc;
  margin-top: 10px;
}

a.thumbnail:hover,
a.thumbnail:active {
  text-decoration: none;
}

// Form extends

.field.form-group {
  position: relative;
}

.disclaimer {
  padding-left: 0;
}

.sprout-email-button {
  padding: 0 !important;
  right: 6px !important;
  bottom: 11px !important;
  margin: 0 !important;
  line-height: 1 !important;
}

.field .instructions {
  color: lighten(@text-color, 25%);
  font-size: @font-size-small;
  margin-bottom: 5px;

  *:last-child {
    margin-bottom: 0;
  }
}

.control-label,
label {
  +.instructions {
    margin-top: -5px;
  }
}

label.required:after {
  content: " *";
  color: @brand-danger;
}

.icon-wrap {
  width: 50px;
  margin: 0px auto;
  height: 50px;
  font-size: 30px;
  background: @brand-red;
  color: @white;
  border-radius: 12px;
  line-height: 50px;
}

.matrix-threecolumntext {
  margin: 0px 0px;
}

.btn-primary {
  background-color: @brand-red;
  border-color: darken(@brand-red, 10%);
}

.media-events {
  .event_title {
    color: #8badbd;
    font-size: 20px;
  }
}

form {
  .heading {
    display: none;
  }
}

.partner-logo {
  height: 70px;
  padding: 0px 20px;

  .img-responsive {
    width: auto;
    height: 100%;
  }
}

.logo {
  border: 1px solid @gray-lighter;
  margin-bottom: 20px;
  height: 250px;
  text-align: center;
  padding: 20px;

  a {
    height: 100%;
    display: block;

    img {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      margin-right: auto;
      margin-left: auto;
    }
  }
}

label {
  font-weight: inherit;
}

.modal-content {
  text-align: center;

  .modal-header {
    border: none;
  }
}

.downloads {
  margin-bottom: 20px;
}

.fullsizeslides {
  text-align: center;

  .section_title {
    margin: 20px 0 60px 0px;
  }

  .slick-next:before,
  .slick-prev:before {
    color: @text-color;
    font-size: 30px;
  }

  .slick-prev {
    left: 15px;
    z-index: 3;
  }

  .slick-next {
    right: 15px;
    z-index: 3;
  }

  img {
    width: 95%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 800px) {
  .social_icons.hidden-xs.hidden-md {
    display: none;
  }
}

#cookie_notice {
  display: none;
  text-align: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 10px;
  z-index: 10000000 !important;
  background-color: #010e18;
  color: #d7d7d7 !important;

  button {
    border-radius: 20px;
    margin: 15px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .cookie-text {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.btn-standard {
  color: #fff;
  background-color: #c01a37;
  border-color: transparent;
  border-radius: 20px;
  padding: 10px 40px;
  font-size: 14px;

}


.btn-standard:hover {
  background-color: #8badbd;
  color: white;
}

.flexcontainer {
  display: flex;
  flex-wrap: wrap;
}

.flexitem {
  flex-grow: 1;
  margin-bottom: 10px;
  /* height: 100px; */
  align-items: left;
}

.flexitem a {
  width: 100%;
}


.flexitem {
  margin-right: 2%;
}